<template>
  <div class="consumables">
    <BaseDialog
      title="新增耗材"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="1030px"
      height="auto"
    >
      <div class="consumables-search">
        <el-form
          :model="searchData"
          label-width="84px"
          label-position="right"
          :inline="true"
          class="demo-ruleForm col-333"
        >
          <!-- <el-form-item label="出库类型：">
            <el-select
              v-model="searchData.dict_after_weight_way"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList[205000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="类别：">
            <el-select
              v-model="searchData.dict_after_weight_way"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList[205000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="">
            <el-input
              v-model="searchData.spell"
              class="w-50 m-2"
              placeholder="搜索"
              clearable
            >
              <template #suffix>
                <el-icon class="el-input__icon" @click="getDataList">
                  <search />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        class="table-ellipsis"
        border
        size="small"
        @select="tableSelect"
        @select-all="tableSelectAll"
      >
        <el-table-column fixed type="selection" width="55" />
        <el-table-column prop="name" label="名称" width="190" />
        <el-table-column prop="medi_spec" label="型号规格" width="120" />
        <el-table-column prop="sell_price" label="价格" width="90" />
        <el-table-column prop="dict_base_unit_name" width="90" label="单位" />
        <el-table-column prop="bar_code" width="120" label="条形码" />
        <el-table-column prop="dict_factory_name" label="生产厂商" />
      </el-table>

      <!--分页 -->
      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="savePatient"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import { reactive, ref, defineExpose, onMounted } from 'vue'
import { Search } from '@element-plus/icons-vue'
import service from '@/utils/request'
import { gitDictList } from '@/utils/tool'
import BaseDialog from '@/components/Dialog/index.vue'
import { ElMessage } from 'element-plus'
import { defineEmits } from 'vue'

const tableData = ref([])
const searchData = reactive({})
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const tableLoading = ref(false)
const visible = ref(false)
onMounted(() => {
  getDict()
})
const initData = (item) => {
  searchData.record_id = item.record_id
  searchData.patient_id = item.patient_id
  visible.value = true
  getDataList()
}
const getDataList = async () => {
  searchData.current = pageData.currentPage
  searchData.size = pageData.pageSize
  tableLoading.value = true
  let res = await service.post('api/complication/consumablesAdd', searchData)
  tableLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data.records
    pageData.totalSum = res.data.total
  }
}

// 字典
const dictList = ref({})
const getDict = async () => {
  dictList.value = await gitDictList(['205000000'])
}

const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}
const handleCurrentChange = (val) => {
  pageData.currentPage = val
  getDataList()
}

// 全选功能
const tableCheData = ref([])
const tableSelect = (row) => {
  tableCheData.value = row
}
const tableSelectAll = (row) => {
  tableCheData.value = row
}

// 保存
const saveLoad = ref(false)
const em = defineEmits(['refreshDataList'])
const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
const savePatient = async () => {
  let row = []
  tableCheData.value.map((item) => {
    row.push(item.id)
  })
  let ruleForm = {
    ...searchData,
    drug_id: row,
    username: userInfo.username,
  }
  saveLoad.value = true
  let res = await service.post('api/complication/drugUse', ruleForm)
  saveLoad.value = false
  if (res.code === 0) {
    ElMessage.success(res.msg)
    visible.value = false
    em('refreshDataList')
  }
}

defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.consumables-search {
  margin-top: 16px;
}
.consumables-but {
  text-align: right;
  margin: 10px 0;
}
</style>
