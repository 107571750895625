<template>
  <div class="consumables">
    <!-- <div class="consumables-but">
      <el-button class="color-green" type="primary" @click="addConsumables">
        <i class="fa fa-plus-circle"></i>新增
      </el-button>
    </div> -->
    <el-table
      v-loading="tableLoading"
      :data="tableData"
      size="small"
      class="table-ellipsis"
      border
    >
      <el-table-column prop="type_name" label="来源" width="100" />
      <el-table-column prop="drug.name" label="名称" width="100" />
      <el-table-column prop="type_name" label="类型" width="120" />
      <el-table-column prop="drug.medi_spec" label="型号规格" width="120" />
      <el-table-column
        prop="drug.dict_factory_name"
        width="120"
        label="生产厂商"
      />
      <el-table-column prop="drug.bar_code" width="160" label="耗材编码" />
      <el-table-column width="160" label="数量">
        <template #default="scope">
          <el-input-number
            v-model="scope.row.num"
            :min="1"
            @change="
              (val) => {
                handleNumChange(val, scope.row)
              }
            "
          />
        </template>
      </el-table-column>
      <el-table-column prop="sell_price" label="单价(元)" />
      <el-table-column prop="drug.dict_base_unit_name" label="单位" />
      <el-table-column prop="username" width="90" label="签名" />
      <el-table-column fixed="right" label="操作" width="100">
        <template #default="scope">
          <el-button type="text" size="small" @click="delParient(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--分页 -->
    <div class="pagination-box">
      <el-pagination
        v-model:currentPage="pageData.currentPage"
        v-model:page-size="pageData.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :background="true"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.totalSum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <Add v-if="isAdd" ref="addRef" @refreshDataList="refreshData"></Add>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  onMounted,
  defineProps,
  watch,
  defineExpose,
  defineEmits,
} from 'vue'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import Add from './add.vue'

const tableData = ref([])
const props = defineProps({
  patientData: {
    type: Object,
  },
})
const searchData = reactive({
  record_id: props.patientData.record_id,
  patient_id: props.patientData.patient_id,
})
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const tableLoading = ref(false)

watch(
  () => props.patientData.record_id,
  (val) => {
    if (val) {
      searchData.patient_id = props.patientData.patient_id
      searchData.record_id = props.patientData.record_id
    } else {
      searchData.patient_id = 0
      searchData.record_id = 0
    }
    getDataList()
  }
)

onMounted(() => {
  getDataList()
})
const em = defineEmits(['stepsUpdate'])
const refreshData = () => {
  getDataList()
  em('stepsUpdate')
}
const getDataList = async () => {
  searchData.current = pageData.currentPage
  searchData.size = pageData.pageSize
  tableLoading.value = true
  let res = await service.post('api/complication/consumablesList', searchData)
  tableLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data.records
    pageData.totalSum = res.data.total
  }
}
const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}
const handleCurrentChange = (val) => {
  pageData.currentPage = val
  getDataList()
}

// 修改耗材数量
const handleNumChange = async (num, item) => {
  let res = await service.post('api/complication/drugEdit', {
    drug_id: item.id,
    num: num,
  })
  if (res.code === 0) {
    ElMessage.success(res.msg)
  }
}
// 删除
const delParient = (item) => {
  ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let res = await service.post('api/complication/drugDelete', {
        drug_id: item.id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
      }
    })
    .catch(() => {})
}

// 添加弹窗
const isAdd = ref(false)
const addRef = ref(null)
defineExpose({
  getDataList,
})
</script>

<style scoped lang="scss">
.consumables-but {
  text-align: right;
  margin: 10px 0;
}
.el-input-number {
  width: 130px;
}
</style>
