<template>
  <div class="patentInfo">
    <el-scrollbar>
      <div class="cont">
        <div class="cont-main col999">
          <el-collapse v-model="activeCollNames" accordion>
            <el-collapse-item name="1" disabled>
              <template #title>
                <div class="updateBox flex-between">
                  <div class="type">
                    <div class="tit" @click="handleChange">
                      <span>医嘱</span>
                      <i
                        class="fa fa-angle-right"
                        :class="!activeCollNames ? 'bottom' : ''"
                      ></i>
                    </div>
                  </div>
                </div>
              </template>
              <div class="tables">
                <el-table
                  v-loading="dataListLoading"
                  :data="tableData"
                  class="table-ellipsis"
                  size="small"
                  border
                  max-height="300px"
                >
                  <el-table-column
                    show-overflow-tooltip
                    prop="drug"
                    label="用药"
                  />
                  <el-table-column
                    width="100"
                    prop="use_type"
                    label="用药方法"
                  />
                  <el-table-column width="120" prop="standard" label="规格" />
                  <el-table-column width="60" prop="dosage" label="计量" />
                  <el-table-column
                    width="90"
                    prop="dosage_unit"
                    label="计量单位"
                  />
                  <el-table-column width="70" prop="frequency" label="频度" />
                  <el-table-column width="70" prop="num" label="用量" />
                  <el-table-column
                    width="90"
                    prop="doctor_name"
                    label="医生名"
                  />
                  <el-table-column
                    show-overflow-tooltip
                    prop="remark"
                    label="备注"
                  />
                  <el-table-column width="70" prop="frequency" label="频度" />
                  <el-table-column
                    width="160"
                    prop="start_time"
                    label="开嘱时间"
                  />
                  <el-table-column
                    width="160"
                    prop="end_time"
                    label="结束时间"
                  />
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </el-scrollbar>
    <BaseDialog
      title="停用"
      :isshow="visibleItem"
      @handleShow="handleShow"
      width="600px"
      top="20%"
    >
      <div class="tongluBox">
        <el-form
          :model="stupData"
          label-width="110px"
          label-position="right"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="停用原因:">
            <el-input
              v-model="stupData.group_name"
              placeholder="请输入停用原因"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visibleItem = false"
          >
            取消
          </el-button>
          <el-button
            @click="accessItem"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
          >
            保存
          </el-button>
        </div>
      </template>
    </BaseDialog>
    <batchLongIerm
      v-if="isBatchLongIerm"
      ref="batchLongIermRef"
      :patientId="patientId"
      @refreshDataList="getDataList"
    ></batchLongIerm>
    <addLongIerm
      v-if="isAddLongIerm"
      ref="addLongIermRef"
      :patientId="patientId"
      @refreshDataList="getDataList"
    ></addLongIerm>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, nextTick, watch } from 'vue'
import { getConstHei } from '@/utils/tool.js'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import batchLongIerm from '@/views/patient/Details/advice/batchLongIerm.vue'
import addLongIerm from '@/views/patient/Details/advice/addLongIerm.vue'
import BaseDialog from '@/components/Dialog/index.vue'
export default {
  name: 'programme',
  components: {
    batchLongIerm,
    addLongIerm,
    BaseDialog,
  },
  props: ['patientData', 'searchListData'],
  emits: ['addCallback'],
  setup(props, ctx) {
    const state = reactive({
      ruleForm: {},
      infoHei: '',
      searchData: {
        is_preopen: '0',
        use_flag: '1',
        drug_type: '0',
        scene: 'report',
      },
      tableData: [],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      dataListLoading: false,
      isBatchLongIerm: false,
      batchLongIermRef: null,
      isAddLongIerm: false,
      addLongIermRef: null,
      stupData: {},
      visibleItem: false,
      saveLoading: false,
      patientId: 0,
      record_id: 0,
      activeCollNames: '1',
    })

    watch(
      () => props.patientData.record_id,
      (val) => {
        if (val) {
          state.patientId = props.patientData.patient_id
          state.record_id = props.patientData.record_id
        } else {
          state.patientId = 0
          state.record_id = 0
        }
        getDataList()
      }
    )

    onMounted(() => {
      state.infoHei = getConstHei()
      if (props.patientData.patient_id) {
        state.patientId = props.patientData.patient_id
        state.record_id = props.patientData.record_id
      } else {
        state.patientId = 0
        state.record_id = 0
      }
      getDataList()
    })
    const getDataList = async () => {
      state.searchData.patient_id = state.patientId
      state.searchData.record_id = state.record_id
      state.dataListLoading = true
      let res = await service.post(
        '/api/patient/get_his_patient_advice_list',
        state.searchData
      )
      state.dataListLoading = false
      if (res.code === 0) {
        state.tableData = res.data.list
      }
    }

    const handleSizeChange = (val) => {
      state.pageData.pageSize = val
      getDataList()
    }

    const handleCurrentChange = (val) => {
      state.pageData.currentPage = val
      getDataList()
    }

    const openWeight = (type, val) => {
      if (!state.patientId || state.patientId === 0) {
        ElMessage({
          message: '请先选择患者',
          type: 'warning',
        })
        return
      }
      switch (type) {
        case 'batchLongIerm':
          state.isBatchLongIerm = true
          nextTick(() => {
            state.batchLongIermRef.initData()
          })
          break
        case 'addLongIerm':
          state.isAddLongIerm = true
          nextTick(() => {
            state.addLongIermRef.initData('', val)
          })
          break
      }
    }

    const cycleFiler = (item) => {
      if (!item) return
      let row = []
      item.forEach((el) => {
        if (el === 'monday') {
          row.push('周一')
        }
        if (el === 'tuesday') {
          row.push('周二')
        }
        if (el === 'wednesday') {
          row.push('周三')
        }
        if (el === 'thursday') {
          row.push('周四')
        }
        if (el === 'friday') {
          row.push('周五')
        }
        if (el === 'saturday') {
          row.push('周六')
        }
        if (el === 'sunday') {
          row.push('周日')
        }
      })
      return row.sort().join(',')
    }

    const handleShow = (val) => {
      state.visibleItem = val
    }

    const deatilsClick = (item) => {
      state.stupData.id = item.id
      state.stupData.group_name = ''
      state.visibleItem = true
    }
    const accessItem = async () => {
      state.saveLoading = true
      let res = await service.post('/api/advice/stop', state.stupData)
      state.saveLoading = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        state.visibleItem = false
        getDataList()
      }
    }
    const modParient = (item) => {
      state.isAddLongIerm = true
      nextTick(() => {
        state.addLongIermRef.initData(item, '0')
      })
    }
    const delParient = (item) => {
      ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post('/api/advice/delete', {
            id: item.id,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
          }
        })
        .catch(() => {})
    }
    // 添加到临时医嘱
    const addTemporary = async (item) => {
      let param = item
      param.id = ''
      param.record_id = state.record_id
      let res = await service.post('/api/dialysis_record/create_tmp', param)
      if (res.code === 0) {
        ElMessage.success(res.msg)
        ctx.emit('addCallback')
      }
    }

    const handleChange = () => {
      if (state.activeCollNames) {
        state.activeCollNames = ''
      } else {
        state.activeCollNames = '1'
      }
    }

    return {
      ...toRefs(state),
      openWeight,
      getDataList,
      accessItem,
      deatilsClick,
      modParient,
      delParient,
      handleShow,
      handleSizeChange,
      handleCurrentChange,
      cycleFiler,
      handleChange,
      addTemporary,
    }
  },
}
</script>

<style scoped lang="scss">
.patentInfo {
  margin-top: 8px;
}
.podBot {
  padding-bottom: 30px;
}
.updateBox {
  height: 36px;
  width: 100%;
  align-items: center;
  .el-radio-group {
    margin-right: 30px;
  }
  .type {
    display: flex;
    align-items: center;
    .tit {
      display: inline-block;
      font-size: 18px;
      color: #000000;
      line-height: 32px;
      margin-right: 20px;
      cursor: pointer;
      .fa {
        font-size: 24px;
        margin-left: 10px;
        color: #999999;
        transition: transform 0.3s;
        &.bottom {
          transform: rotate(90deg);
        }
      }
    }
  }
}
.cont-main {
  &:deep(.el-collapse-item__arrow) {
    width: 0;
  }
  &:deep(.el-collapse-item__header) {
    border-bottom: 0;
    cursor: default;
  }
}
.tables {
  margin-top: 10px;
}
.parient-tag {
  .el-tag {
    margin-right: 10px;
  }
}
.tongluBox {
  margin-top: 20px;
}
</style>
