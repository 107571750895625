<template>
  <div class="fatherHeight" :style="{ height: infoHei }">
    <!-- 中间内容部分 -->
    <div class="bodycliss">
      <!-- 左侧 -->
      <div class="lifebody">
        <el-scrollbar>
          <div v-if="list.length === 0">
            <el-empty description="暂无数据！"></el-empty>
          </div>

          <div
            class="zy-bord"
            :class="[listIndex === index ? 'active' : '']"
            v-for="(item, index) in list"
            :key="index"
            @click="bodycl(index, item)"
          >
            <div class="userinfo-item">{{ item.date }}</div>
          </div>
        </el-scrollbar>
      </div>

      <!-- 右侧 -->
      <div class="righbody" v-if="list.length > 0">
        <!-- 头部 -->
        <div class="headfixed">
          <!-- 按钮区 -->
          <div class="hanbut flex-between">
            <div class="hanbuts">
              <div
                :class="[BtnIndex === index ? 'butnlis' : 'butnli']"
                v-for="(items, index) in btnlist"
                :key="index"
                @click="butncls(index)"
              >
                {{ items.name }}
              </div>
            </div>
            <!-- 右侧按钮 -->
            <!-- <div
              style="
                display: flex;
                justify-content: space-between;
                width: 226px;
              "
            >
              <el-button
                v-print="print"
                :loading="printLoad"
                class="color-vice"
                type="primary"
              >
                <i class="fa fa-refresh"></i>打印小票
              </el-button>
              <el-button @click="Refresh" class="color-vice" type="primary">
                <i class="fa fa-refresh"></i>刷新
              </el-button>
            </div> -->
          </div>
        </div>
        <div id="printArea">
          <div class="printAreaBox">
            <div class="name">医院名称</div>
            <div class="time">{{ printData.time }}</div>
            <div class="print-item">
              <span class="pl">{{ printData.base.name }}</span>
              <span class="pl">{{ printData.base.dict_code }}</span>
              <span class="pl">{{ printData.base.bed_num }}床</span>
            </div>
            <div class="print-item">
              <span class="pl">{{ printData.base.dict_sex_name }}</span>
              <span class="pl">{{ printData.base.age }}岁</span>
            </div>
            <div class="print-item">
              <span class="pl">{{
                printData.base.dict_dialysis_type_name
              }}</span>
              <span class="pl"
                >{{ printData.base.dialysis_hour }}时{{
                  printData.base.dialysis_min
                }}分</span
              >
            </div>
            <div class="print-item">
              抗凝剂：{{ printData.base.dict_kangning_name }}
            </div>
            <div class="print-item">
              首剂：{{ printData.base.kangning_shouji_num
              }}{{ printData.base.dict_kangning_shouji_unit_name }}
            </div>
            <div class="print-item">
              上次透前体重：{{ printData.base.befor_weight }}kg
            </div>
            <div class="print-item">
              干体重：{{ printData.base.dry_weight }}kg
            </div>
            <div class="print-item">
              体重添长：{{ printData.base.befor_raise_weight }}kg
            </div>
            <div class="print-item">
              血流速：{{ printData.base.xueliu_speed_min }}ml/min
            </div>
            <div class="print-item">
              透析器：{{ printData.base.dict_touxiqi_name }}
            </div>
            <div class="print-item">
              透析液流速：{{ printData.base.touxiye_speed }}ml/min
            </div>
            <div class="print-item">
              通路类型：{{ printData.tonglu.dict_tonglu_type_name }}
            </div>
            <div class="print-item">
              通路部位：{{ printData.tonglu.dict_tonglu_position_name }}
            </div>
            <div class="print-item">
              介入方式：{{ printData.tonglu.dict_intervene_type_name }}
            </div>
            <template v-if="printData.chuanci.is_fistula == 2">
              <div class="print-item">
                A端穿刺方法：{{ printData.chuanci.dict_a_chuanci_way_name }}
              </div>
              <div class="print-item">
                A端穿刺类型：{{ printData.chuanci.dict_a_chuanci_zhen_name }}
              </div>
              <div class="print-item">
                A端穿刺点距吻合口距离：{{ printData.chuanci.a_distance }}cm
              </div>
              <div class="print-item">
                V端穿刺方法：{{ printData.chuanci.dict_v_chuanci_way_name }}
              </div>
              <div class="print-item">
                V端穿刺类型：{{ printData.chuanci.dict_v_chuanci_zhen_name }}
              </div>
              <div class="print-item">
                V端穿刺点距吻合口距离：{{ printData.chuanci.v_distance }}cm
              </div>
              <div class="print-item">
                AtV端两穿刺点间距离：{{ printData.chuanci.atv_distance }}cm
              </div>
            </template>
            <template v-else>
              <div class="print-item">
                穿刺方式：{{ printData.chuanci.dict_chuanci_type_name }}
              </div>
            </template>
            <div class="print-item">K：{{ printData.base.k }}mmol/L</div>
            <div class="print-item">Ca：{{ printData.base.ca }}mmol/L</div>
            <div class="print-item">Na：{{ printData.base.na }}mmol/L</div>
          </div>
        </div>
        <!-- 执行医嘱 -->
        <my-YZ
          :patientData="actPatientData"
          :searchListData="formInline"
          @stepsUpdate="stepsUpdate"
          ref="adviceRef"
          v-if="BtnIndex === 1"
        ></my-YZ>

        <!-- 透前评估 -->
        <my-PG
          :patientData="actPatientData"
          @stepsUpdate="stepsUpdate"
          ref="assessmentRef"
          v-if="BtnIndex === 2"
        ></my-PG>

        <!-- 检测记录 -->
        <my-JC
          :patientData="actPatientData"
          @stepsUpdate="stepsUpdate"
          ref="testingRef"
          v-if="BtnIndex === 3"
        ></my-JC>
        <!-- 透后小结 -->
        <my-XJ
          :patientData="actPatientData"
          @stepsUpdate="stepsUpdate"
          ref="estimateRef"
          v-if="BtnIndex === 4"
        ></my-XJ>
        <!-- 耗材记录 -->

        <Consumables
          :patientData="actPatientData"
          @stepsUpdate="stepsUpdate"
          ref="consumablesRef"
          v-if="BtnIndex === 5"
        ></Consumables>

        <!-- 并发症 -->
        <Complication
          :create="create"
          v-if="BtnIndex === 6"
          @stepsUpdate="stepsUpdate"
          ref="mychild"
        ></Complication>

        <!-- 基本信息 -->
        <div v-show="BtnIndex === 0" class="Dialysis">
          <!-- 头部 -->
          <div class="jniop"></div>
          <div class="juliss">基本信息</div>

          <el-form
            ref="ruleFormRef"
            :model="create"
            :rules="rules"
            :inline="true"
            class="demo-ruleForm"
          >
            <el-form-item label="床位号：">
              <el-input v-model="create.bed_id" disabled />
            </el-form-item>
            <el-form-item label="透析次数：" prop="dialysis_count">
              <el-input v-model="create.dialysis_count" placeholder="请填写" />
            </el-form-item>

            <el-form-item label="医保类型：">
              <el-select
                v-model="create.dict_payment_type"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[103000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="护理等级：">
              <el-select
                v-model="create.dict_huli_level"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[119000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上次透析消毒：">
              <el-select
                v-model="create.last_disinfection_flag"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in lasttions"
                  :key="item.index"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消毒液残留：">
              <el-select
                v-model="create.last_disinfectant_residue_flag"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in lasttions"
                  :key="item.index"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="住院患者：">
              <el-select
                v-model="create.hospital_flag"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in lasttions"
                  :key="item.index"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="门诊号：">
              <el-input v-model="create.outpatient_no" placeholder="请填写" />
            </el-form-item>
            <el-form-item label="穿刺信息：">
              <el-input v-model="create.chuanci_desc" placeholder="请填写" />
            </el-form-item>
            <el-form-item label=" 过敏史：" style="width: 100%">
              <el-input
                v-model="create.allergy_history"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
            <el-form-item label=" 诊断：" style="width: 100%">
              <el-input
                v-model="create.diagnosis"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <!-- 透析病情 -->
        <div v-show="BtnIndex === 0" class="Dialysis">
          <!-- 头部 -->
          <div class="jniop"></div>
          <div class="juliss">透析病情</div>

          <el-form
            ref="ruleFormRef"
            :model="create"
            :rules="rules"
            :inline="true"
            class="demo-ruleForm"
          >
            <el-form-item label="干体重：">
              <el-input
                v-model="create.dry_weight"
                @change="Target_dewatering"
                placeholder="请填写"
              >
                <template #append>kg</template>
              </el-input>
            </el-form-item>

            <el-form-item label="透前体重：">
              <el-input
                @change="Throughweightafter"
                v-model="create.befor_weight"
                placeholder="请填写"
              >
                <template #append>kg</template>
              </el-input>
            </el-form-item>

            <el-form-item label="附加体重：">
              <el-input
                v-model="create.add_weight"
                @change="Theadditionalweight"
                placeholder="请填写"
              >
                <template #append>kg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="称重方式：">
              <el-select
                v-model="create.befor_weight_way"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[178000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="实际透前体重：">
              <el-input
                v-model="create.befor_clean_weight"
                placeholder="请填写"
                @change="weight"
              >
                <template #append>kg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="体重增长：">
              <div style="display: flex">
                <el-input
                  v-model="create.befor_raise_weight"
                  placeholder="请填写"
                  disabled
                >
                  <template #append>kg</template>
                </el-input>
                <el-input
                  style="border-left: none"
                  v-model="create.befor_raise_weight_rate"
                  placeholder="请填写"
                  disabled
                >
                  <template #append>%</template>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="收压缩：">
              <el-input
                v-model="create.befor_systolic_pressure"
                placeholder="请填写"
              >
                <template #append>mmHg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="舒张压：">
              <el-input
                v-model="create.befor_diastolic_pressure"
                placeholder="请填写"
              >
                <template #append>mmHg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="脉搏：">
              <el-input v-model="create.befor_pulse" placeholder="请填写">
                <template #append>次/分</template>
              </el-input>
            </el-form-item>
            <el-form-item label="测量部位：">
              <el-select
                v-model="create.befor_pressure_way"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[179000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="体温：">
              <el-input v-model="create.befor_temperature" placeholder="请填写">
                <template #append>°C</template>
              </el-input>
            </el-form-item>
            <el-form-item label="24小时尿量：">
              <el-input v-model="create.befor_urine24" placeholder="请填写">
                <template #append>ml</template>
              </el-input>
            </el-form-item>
            <el-form-item label="呼吸：">
              <el-input v-model="create.befor_breath" placeholder="请填写">
                <template #append>次/分</template>
              </el-input>
            </el-form-item>
            <el-form-item label=" 病情：" style="width: 100%">
              <el-input
                v-model="create.condition"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <!-- 透析医嘱 -->
        <div
          v-show="BtnIndex === 0"
          style="
            display: flex;
            box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.12);
            flex-wrap: wrap;
            align-content: flex-start;
            margin-bottom: 25px;
          "
        >
          <!-- 头部 -->
          <div class="jniop"></div>
          <div class="juliss">透析医嘱</div>
          <el-form
            ref="ruleFormRef"
            :model="create"
            :rules="rules"
            :inline="true"
            class="demo-ruleForm"
          >
            <el-form-item label="透析方式：" prop="dict_dialysis_type">
              <el-select
                v-model="create.dict_dialysis_type"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  @click="dialysis_way(item)"
                  v-for="item in dictList[100000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="血管通路：">
              <el-select
                v-model="create.dict_tonglu"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[110000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="血透器：">
              <el-select
                v-model="create.dict_touxiqi"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[113000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="透析液k：">
              <el-input v-model="create.k" placeholder="请填写">
                <template #append>mmol/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析液Ca：">
              <el-input v-model="create.ca" placeholder="请填写">
                <template #append>mmol/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析液Na：">
              <el-input v-model="create.na" placeholder="请填写">
                <template #append>mmol/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析液HCo3：">
              <el-input v-model="create.hco3" placeholder="请填写">
                <template #append>mmol/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析液温度：">
              <el-input v-model="create.touxiye_temp" placeholder="请填写">
                <template #append>°C</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析液流速：">
              <el-input v-model="create.touxiye_speed" placeholder="请填写">
                <template #append>mL/min</template>
              </el-input>
            </el-form-item>
            <el-form-item label="抗凝剂：">
              <div style="display: flex">
                <el-select
                  v-model="create.dict_kangning"
                  class="m-2"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dictList[116000000]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
                <div class="circie" @click="Kning">
                  <el-icon><circle-plus /></el-icon>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="首剂：">
              <el-input
                v-model="create.kangning_shouji_num"
                placeholder="请填写"
                class="input-with-select"
              >
                <template #append>
                  <el-select v-model="create.kangning_shouji_unit">
                    <el-option
                      v-for="item in dictList[157000000]"
                      :key="item.id"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="追加/维持：">
              <el-select v-model="create.kangning_add_name" class="m-2">
                <el-option
                  v-for="item in dictList[116000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <div class="closhand" v-if="Kningvais">
              <div class="Close" @click="Kning">
                <el-icon><close /></el-icon>
              </div>
              <div class="clostext">第二针抗凝剂：</div>
              <div>
                <el-select
                  v-model="create.dict_kangning_part_1_name"
                  class="m-2"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dictList[116000000]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </div>

              <div style="width: 8%; margin: 0 10px 0 40px">
                <el-select
                  v-model="create.dict_kangning_operation_1_name"
                  class="m-2"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dictList[316000000]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </div>

              <div style="width: 20%">
                <div style="margin: 0 10px; display: flex; width: 264px">
                  <input
                    class="inputlis"
                    style="width: 200px"
                    v-model="create.kangning_num_part_1"
                  />
                  <div style="width: 120px">
                    <el-select
                      v-model="create.kangning_unit_part_1"
                      class="m-22"
                    >
                      <el-option
                        v-for="item in dictList[157000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>

              <div style="width: 8%; margin: 0 10px 0 40px">
                <el-select
                  v-model="create.dict_kangning_operation_2_name"
                  class="m-2"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dictList[316000000]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </div>

              <div style="width: 20%">
                <div style="margin: 0 10px; display: flex; width: 264px">
                  <input
                    class="inputlis"
                    style="width: 200px"
                    v-model="create.kangning_num_part_2"
                  />
                  <div style="width: 120px">
                    <el-select
                      v-model="create.kangning_unit_part_2"
                      class="m-22"
                    >
                      <el-option
                        v-for="item in dictList[157000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>

              <div
                style="display: flex; width: 35%; margin: 10px 10px 10px 6px"
              >
                <div class="bodytxet">维持时间：</div>
                <div
                  style="
                    margin: 0 10px;
                    display: flex;
                    width: 279px;
                    height: 32px;
                  "
                >
                  <input
                    class="inputlis"
                    v-model="create.kangning_add_time_2_hour"
                  />
                  <div class="KGclis">时</div>
                  <input
                    class="BFbi"
                    v-model="create.kangning_add_time_2_min"
                  />
                  <div class="BFbiclis">分</div>
                </div>
              </div>
            </div>
            <el-form-item label="追加/维持量：">
              <el-input
                v-model="create.kangning_add_num"
                placeholder="请填写"
                class="input-with-select"
              >
                <template #append>
                  <el-select v-model="create.dict_kangning_add_num_unit">
                    <el-option
                      v-for="item in dictList[157000000]"
                      :key="item.id"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="维持时间：">
              <div style="display: flex">
                <input
                  class="inputlis"
                  v-model="create.kangning_add_time_hour"
                />
                <div class="KGclis">时</div>
                <input class="BFbi" v-model="create.kangning_add_time_min" />
                <div class="BFbiclis">分</div>
              </div>
            </el-form-item>
            <el-form-item label="回血量：">
              <el-input
                v-model="create.huixue_num"
                placeholder="请填写"
                @change="Target_dewatering"
              >
                <template #append>ml</template>
              </el-input>
            </el-form-item>
            <el-form-item label="透中摄入：">
              <el-input
                v-model="create.intake"
                placeholder="请填写"
                @change="Target_dewatering"
              >
                <template #append>kg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="目标脱水：">
              <el-input v-model="create.target_warter" placeholder="请填写">
                <template #append
                  ><div style="display: flex">
                    <div style="width: 20px">kg</div>
                    <div></div>
                    <div>max3</div>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="存：">
              <el-input v-model="create.store_warter" placeholder="请填写">
                <template #append>kg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="最大脱水：">
              <el-input v-model="create.max_tuoshui_num" disabled>
                <template #append>kg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="灌流器：">
              <el-select
                v-model="create.dict_guanliuqi"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[115000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="血滤器：">
              <el-select
                v-model="create.dict_xuelvqi"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[114000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="透析时长：">
              <div style="display: flex">
                <input class="inputlis" v-model="create.dialysis_hour" />
                <div class="KGclis">时</div>
                <input class="BFbi" v-model="create.dialysis_min" />
                <div class="BFbiclis">分</div>
              </div>
            </el-form-item>
            <el-form-item label="血流速：">
              <div style="display: flex">
                <input class="inputlis" v-model="create.xueliu_speed_min" />
                <div class="KGclis">-</div>
                <input class="BFbi" v-model="create.xueliu_speed_max" />
                <div class="BFbiclis" style="width: 60px">ml/min</div>
              </div>
            </el-form-item>
            <el-form-item label="回血泵速：">
              <el-input v-model="create.huixue_beng_speed" placeholder="请填写">
                <template #append>ml/min</template>
              </el-input>
            </el-form-item>
            <el-form-item label="置换方式：">
              <el-select
                :disabled="disabled"
                v-model="create.dict_zhihuan_type"
                class="m-2"
              >
                <el-option
                  v-for="item in dictList[118000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="置液总量：">
              <el-input v-model="create.zhihuan_num" :disabled="disabled">
                <template #append>L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="实际置液：">
              <el-input
                :disabled="disabled"
                v-model="create.actual_zhihuanye_total"
              >
                <template #append>L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="置液流速：">
              <div style="display: flex">
                <input
                  class="inputlis"
                  :disabled="disabled"
                  v-model="create.zhihuan_speed_min"
                />
                <div class="KGcliss">-</div>
                <input
                  class="BFbi"
                  style="width: 80px"
                  v-model="create.zhihuan_speed_max"
                  :disabled="disabled"
                />
                <div class="BFbiclis" style="width: 60px">L/h</div>
              </div>
            </el-form-item>
            <el-form-item label="超滤方式：">
              <el-select
                v-model="create.dict_chaolv_mode"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[339400000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="冲水方式：">
              <el-select
                v-model="create.dict_flush_mode"
                class="m-2"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[339500000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注：" style="width: 100%">
              <el-input
                v-model="create.remark"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <!-- 签名 -->
        <div v-show="BtnIndex === 0" class="Dialysis" style="height: 120px">
          <!-- 头部 -->
          <div class="jniop"></div>
          <div class="juliss">签名</div>

          <el-form
            ref="ruleFormRef"
            :model="create"
            :rules="rules"
            :inline="true"
            class="demo-ruleForm"
          >
            <el-form-item label="冲管者：">
              <el-select
                v-model="create.cgz_id_name"
                class="m-2"
                placeholder="请选择"
                @change="a1"
              >
                <el-option
                  v-for="item in userInfo"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="换药者：">
              <el-select
                v-model="create.ccz_id_name"
                class="m-2"
                placeholder="请选择"
                @change="a2"
              >
                <el-option
                  v-for="item in userInfo"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接血者：">
              <el-select
                v-model="create.jxz_id_name"
                class="m-2"
                placeholder="请选择"
                @change="a3"
              >
                <el-option
                  v-for="item in userInfo"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CirclePlus, Close } from '@element-plus/icons-vue'
import myYZ from './advice/index.vue'
import myPG from './THPG.vue'
import myJC from './testing/index.vue'
import myXJ from './estimate/THXJ.vue'
import Complication from './BFZ.vue'
import Consumables from './consumables/index.vue'
import service from '@/utils/request'
import { getnoTopConstHei, gitDictList, TimeYear } from '@/utils/tool'
import { ElMessageBox, ElMessage } from 'element-plus'
// import { isTemplate } from 'element-plus/lib/utils'
import print from 'vue3-print-nb'
export default {
  components: {
    CirclePlus,
    myYZ,
    myPG,
    myJC,
    Close,
    myXJ,
    Consumables,
    Complication,
  },
  directives: {
    print,
  },
  data() {
    return {
      stepsData: {},
      listIndex: 0,
      sutas: true,
      preconval: {},
      value1: '',
      input3: '',
      lisding: false,
      Kningvais: false,
      tmbleval: false,
      puncture: false,
      bodyvlus: false,
      bedval: false,
      addtmb: true,
      tmpl_content: '',
      formInline: {
        user: '上午',
        time: TimeYear(new Date()),
        beforeOpenCallback: () => {
          console.log('1')
          return false
        },
      },
      printLoad: false,
      print: {
        id: 'printArea',
        popTitle: '打印小条',
      },
      printData: {
        base: {},
        tonglu: {},
        chuanci: {},
      },
      rules: {
        tmpl_name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
          { min: 1, max: 5, message: '长度在 1到 5 个字符', trigger: 'blur' },
        ],
        tmpl_content: [
          { required: true, message: '请填写模板内容', trigger: 'blur' },
        ],
        dialysis_count: [
          { required: true, message: '请填写', trigger: 'blur' },
        ],
        dict_dialysis_type: [
          { required: true, message: '请填写', trigger: 'blur' },
        ],
      },

      lasttions: [
        { name: '是', code: 1 },
        { name: '否', code: 0 },
      ],
      list: [],
      tmplist: [],
      btnlist: [
        { name: '透析信息' },
        { name: '执行医嘱' },
        { name: '透前评估' },
        { name: '监测记录' },
        { name: '透后小结' },
        { name: '耗材记录' },
        { name: '并发症' },
      ],
      options: [
        {
          value: '上午',
          label: '上午',
        },
        {
          value: '下午',
          label: '下午',
        },
        {
          value: '晚上',
          label: '晚上',
        },
      ],
      BtnIndex: 0,
      value: '',
      Ylist: [],
      is_sign: 1,
      hospid: '',
      create: {
        patient_id: '',
        bed_id: '',
        dialysis_count: '',
        dict_payment_type: '',
        dict_huli_level: '',
        last_disinfection_flag: '',
        last_disinfectant_residue_flag: '',
        hospital_flag: '',
        outpatient_no: '',
        chuanci_desc: '',
        allergy_history: '',
        diagnosis: '',
        dry_weight: '',
        befor_weight: '',
        add_weight: '',
        befor_weight_way: '',
        befor_clean_weight: '',
        befor_raise_weight: '',
        befor_raise_weight_rate: '',
        befor_systolic_pressure: '',
        befor_diastolic_pressure: '',
        befor_pulse: '',
        befor_pressure_way: '',
        befor_temperature: '',
        befor_urine24: '',
        befor_breath: '',
        condition: '',
        dict_dialysis_type: '',
        dict_tonglu: '',
        dict_touxiqi: '',
        k: '',
        ca: '',
        na: '',
        hco3: '',
        touxiye_temp: '',
        touxiye_speed: '',
        dict_langning: '',
        kangning_shouji_num: '',
        kangning_shouji_unit: '',
        kangning_add_name: '',
        kangning_add_num: '',
        dict_kangning_add_num_unit: '',
        kangning_add_time_hour: '',
        kangning_add_time_min: '',
        dict_kangning_part_1_name: '',
        dict_kangning_operation_1_name: '',
        kangning_num_part_1: '',
        kangning_unit_part_1: '',
        dict_kangning_operation_2_name: '',
        kangning_num_part_2: '',
        kangning_unit_part_2: '',
        kangning_add_time_2_hour: '',
        kangning_add_time_2_min: '',
        huixue_num: '',
        intake: '',
        target_warter: '',
        store_warter: '',
        max_tuoshui_num: '',
        dict_guanliuqi: '',
        dict_xuelvqi: '',
        dialysis_hour: '',
        dialysis_min: '',
        xueliu_speed_min: '',
        xueliu_speed_max: '',
        huixue_beng_speed: '',
        dict_zhihuan_type: '',
        zhihuan_num: '',
        actual_zhihuanye_total: '',
        zhihuanye_speed_min: '',
        zhihuanye_speed_max: '',
        dict_chaolv_mode: '',
        dict_flush_mode: '',
        remark: '',
        cgz_id: '',
        ccz_id: '',
        jxz_id: '',
        sjz_id: '',
        schedule_bed_id: null,
        dict_code: '',
        date: '',
      },
      group: [],
      tmb_id: null,
      userInfo: [],
      dictList: {},
      ruleForm: {},
      tmb_Index: 0,
      record_id: null,
      imgval:
        'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',

      actPatientData: {},
      signin: false,
      radiopiercing: '1',
      Amethodpuncture: '',
      Apiercingtype: '',
      AThepiercing: '',
      Vmethodpuncture: '',
      Vpiercingtype: '',
      VThepie: '',
      AVpuncture: '',
      Adistance: '',
      Vdistance: '',
      AVdistance: '',
      disabled: true,
      reception: [],
      receptionIndex: 0,
      bed_no: [],
      bidIndex: null,
      sid: null,

      searhDia: {
        current: 1,
        size: 10,
        patient_id: '',
      },
      infoHei: '',
    }
  },
  created() {
    this.searhDia.patient_id = this.$route.params.id
    this.infoHei = getnoTopConstHei()
    // 获取院区列表
    this.getDialysisList()
    // 获取字典
    this.getDict()
    // 获取缓存数据
    const user = localStorage.getItem('ms_userData')
    this.userInfo.push(JSON.parse(user))
  },
  methods: {
    async printInit() {
      if (!this.actPatientData.record_id) {
        return
      }
      this.printLoad = true
      const res = await service.post('/api/dialysis_record/getStampValue', {
        record_id: this.actPatientData.record_id,
      })
      this.printLoad = false
      if (res.code === 0) {
        this.printData = res.data
        this.printData.time = TimeYear(new Date())
      }
    },
    // 签到切换
    Qiando() {
      this.sutas = !this.sutas
      if (!this.sutas) {
        this.is_sign = 0
      } else {
        this.is_sign = 1
      }
      this.create = {}
      this.user()
    },
    // 左侧列表点击事件
    async bodycl(index, item) {
      this.actPatientData = item
      this.actPatientData.record_id = this.actPatientData.id
      this.listIndex = index
      this.create.schedule_bed_id = item.id
      this.create.patient_id = item.patient_id
      this.create.bed_id = item.bed_num
      this.record_id = item.record_id
      let data = {
        patient_id: item.patient_id,
        date: item.date,
        dict_code: item.dict_code,
      }
      const val = await service.post('/api/dialysis_record/info', data)
      if (val.code === 0) {
        if (val.data.length != 0) {
          this.create = val.data
          this.create.bed_id = item.bed_num
          this.create.schedule_bed_id = item.id
          if (
            this.create.dict_dialysis_type_name == 'HF' ||
            this.create.dict_dialysis_type_name == 'HDF' ||
            this.create.dict_dialysis_type_name == 'HDF-FX600+HP' ||
            this.create.dict_dialysis_type_name == 'HP+HF'
          ) {
            this.disabled = false
          } else {
            this.disabled = true
          }
          this.weight(this.create.befor_clean_weight)
          // 点击患者列表 更新并发症列表
          if (this.BtnIndex === 6) {
            this.$refs.mychild.monitor()
          }
        }
      }
    },
    // 选择床位号确定
    receconfirm() {
      let val = JSON.parse(JSON.stringify(this.preconval))
      this.create.bed_id = val.bed_no
      this.bedval = false
    },
    // 打开签到弹窗
    Pclick(val) {
      console.log(val)
      this.group = [val.group]
      this.bodyvlus = true
    },
    // 取消按钮
    QXclick() {
      this.bodyvlus = false
    },
    //刷新
    Refresh() {
      // this.$router.go(0)
      let that = this
      ElMessageBox.confirm(
        '刷新当前患者数据会丢失当前未保存的数据，是否继续？',
        '操作提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(async () => {
          switch (that.BtnIndex) {
            case 0:
              that.initwar(this.actPatientData)
              break
            case 1:
              that.$refs.adviceRef.getDataList()
              break
            case 2:
              that.$refs.assessmentRef.getDataList()
              break
            case 3:
              that.$refs.testingRef.getDataList()
              break
            case 4:
              that.$refs.estimateRef.getDataList()
              break
            case 5:
              that.$refs.consumablesRef.getDataList()
              break
            case 6:
              that.$refs.mychild.monitor()
              break
          }
        })
        .catch(() => {})
    },
    // 冲管者
    a1(e) {
      this.create.cgz_id = e
    },
    // 换药者
    a2(e) {
      this.create.ccz_id = e
    },
    // 接血者
    a3(e) {
      this.create.jxz_id = e
    },
    // 按钮选择
    butncls(val) {
      this.BtnIndex = val
    },
    // 追加抗凝剂
    Kning() {
      this.Kningvais = !this.Kningvais
    },
    // 院区列表
    async Yanqu() {
      const val = await service.post('/api/hospital/list', { parent_id: 0 })
      if (val.code === 0) {
        this.hospi(val.data[0].id)
        this.Ylist = val.data
        this.formInline.Yval = val.data[0].name
        this.hospid = val.data[0].id
      }

      this.user()
    },
    // 获取用户列表
    async getDialysisList() {
      const val = await service.post(
        '/api/patient/dialysis_list',
        this.searhDia
      )
      if (val.code === 0) {
        this.list = val.data.records
        if (this.list.length > 0) {
          if (this.listIndex < this.list.length - 1) {
            this.actPatientData = this.list[this.listIndex]
          } else {
            this.actPatientData = this.list[0]
          }
          this.actPatientData.record_id = this.actPatientData.id
          this.record_id = this.list[0].id
          this.create.patient_id = this.list[0].patient_id
          this.create.schedule_bed_id = this.list[0].id
          this.create.bed_id = this.list[0].bed_num
          // 调取信息
          this.initwar(this.actPatientData)
        } else {
          this.actPatientData = {}
        }
      }
    },
    // 页面刚进来 默认加载第一个患者数据
    async initwar(item) {
      let data = {
        patient_id: item.patient_id,
        date: item.date,
        dict_code: item.dict_code,
      }
      const val = await service.post('/api/dialysis_record/info', data)
      if (val.code === 0) {
        if (val.data.length != 0) {
          this.create = val.data
          if (
            this.create.dict_dialysis_type_name == 'HF' ||
            this.create.dict_dialysis_type_name == 'HDF' ||
            this.create.dict_dialysis_type_name == 'HDF-FX600+HP' ||
            this.create.dict_dialysis_type_name == 'HP+HF'
          ) {
            this.disabled = false
          } else {
            this.disabled = true
          }
          this.weight(this.create.befor_clean_weight)
        } else {
          this.actPatientData = {}
        }
      }
    },
    //删除签到
    async Delete() {
      const val = await service.post('/api/dialysis_record/delete_sign', {
        schedule_bed_id: this.create.schedule_bed_id,
      })
      if (val.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success',
        })
        this.user()
      }
    },
    // 删除透析记录
    async deleiDialysis() {
      const val = await service.post('/api/dialysis_record/delete', {
        patient_id: this.create.patient_id,
        id: this.record_id,
      })
      if (val.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success',
        })
        this.user()
      }
    },
    // 院区选择
    async hospi(id) {
      const res = await service.post('/api/hospital/list', { parent_id: id })
      if (res.code === 0) {
        console.log(res)
        this.reception = res.data
        this.sid = res.data[0].hospital.id
        this.receptions(0, res.data[0].id)
      }
      this.hospid = id

      this.user()
    },
    // 查询
    search() {
      this.user()
    },
    // 重置
    refresh() {
      this.$message({
        message: '重置成功',
        type: 'success',
      })
      this.formInline.user = '上午'
      this.formInline.time = TimeYear(new Date())
      this.formInline.Yval = this.Ylist[0].name
      this.hospid = this.Ylist[0].id
    },
    // 保存
    async conserve() {
      if (!this.create.dialysis_count) {
        return this.$message.error('透析次数必填')
      } else if (!this.create.dict_dialysis_type) {
        return this.$message.error('透析方式必填')
      } else {
        if (this.actPatientData.is_sign === 0) {
          ElMessageBox.confirm(
            '当前患者还未签到，请先签到后在进行保存',
            '操作提示',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: 'warning',
            }
          )
            .then(async () => {
              this.signin = true
              // 先签到
              this.bodyvlus = true
            })
            .catch(() => {})
        } else {
          this.create.date = this.formInline.time
          this.create.dict_code = this.formInline.user
          this.create.hospital_area_id = this.hospid
          if (this.radiopiercing === '1') {
            this.create.is_fistula = 134000000
          } else {
            this.create.is_fistula = 350000000
          }

          delete this.create.id
          const res = await service.post(
            '/api/dialysis_record/save_record',
            this.create
          )
          if (res.code === 0) {
            this.$message({
              message: '保存成功',
              type: 'success',
            })
            this.listIndex = 0
            this.user()
          }
        }
      }
    },

    // 获取字典数据
    async getDict() {
      this.dictList = await gitDictList([
        '103000000',
        '119000000',
        '100000000',
        '339400000',
        '339500000',
        '118000000',
        '115000000',
        '114000000',
        '113000000',
        '157000000',
        '176000000',
        '178000000',
        '116000000',
        '179000000',
        '316000000',
        '110000000',
        '134000000',
        '135000000',
        '136000000',
        '350000000',
      ])
    },
    // 透析方式选择
    dialysis_way(val) {
      console.log(val)
      ElMessageBox.confirm('是否提取' + val.name + '方案信息', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      })
        .then(async () => {
          if (
            val.name == 'HF' ||
            val.name == 'HDF' ||
            val.name == 'HDF-FX600+HP' ||
            val.name == 'HP+HF'
          ) {
            this.disabled = false
          } else {
            this.disabled = true
          }
          const res = await service.post('/api/dialysis_record/init_way', {
            patient_id: this.create.patient_id,
            dict_dialysis_type: val.code,
          })
          if (res.code === 0) {
            if (!Array.isArray(res.data)) {
              this.create = Object.assign(this.create, res.data)
              this.create.bed_id = this.list[0].bed_num
              this.create.schedule_bed_id = this.actPatientData.id
            } else {
              ElMessage({
                showClose: true,
                message: '暂无数据！',
                type: 'warning',
              })
            }
          }
        })
        .catch(() => {})
    },
    //使用模板添加
    async Usetemplate() {
      const val = await service.post('/api/case_tmpl/list')
      this.tmplist = val.data
      this.tmbleval = true
    },
    // 模板操作按钮
    tmb_btn(row) {
      this.create.condition = this.create.condition + row.tmpl_content + ','
      this.tmbleval = false
      console.log(row)
    },
    // 查看模板内容
    tmp_tabclick(e) {
      this.tmpl_content = e.tmpl_content
    },
    // 模板取消
    tmb_QX() {
      this.tmbleval = false
    },
    // 模板确认
    async submitForm() {
      if (this.tmb_Index == 1) {
        if (!this.ruleForm.tmpl_content || !this.ruleForm.tmpl_name) {
          this.$message({
            showClose: true,
            message: '请完整输入内容',
            type: 'error',
          })
        } else {
          const val = await service.post('/api/case_tmpl/create', this.ruleForm)
          if (val.code === 0) {
            this.$message({
              message: '添加成功',
              type: 'success',
            })
            this.ruleForm = {}
            this.addtmb = true
            this.Usetemplate()
          }
        }
      } else {
        const data = {
          id: this.tmb_id,
          tmpl_name: this.ruleForm.tmpl_name,
          tmpl_content: this.ruleForm.tmpl_content,
        }
        const res = await service.post('/api/case_tmpl/edit', data)
        if (res.code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success',
          })
          this.addtmb = true
          this.ruleForm = {}
          this.Usetemplate()
        }
      }
    },
    // 计算体重增长率
    async weight(e) {
      if (e && this.create.dry_weight) {
        this.create.befor_raise_weight = (e - this.create.dry_weight).toFixed(2)
        this.create.befor_raise_weight_rate = (
          (this.create.befor_raise_weight / this.create.dry_weight) *
          100
        ).toFixed(2)
      }
    },
    // 新增模板按钮
    tmb_handebtn() {
      this.addtmb = !this.addtmb
      this.tmb_Index = 1
    },
    // 模板删除
    async tmb_dele(id) {
      const val = await service.post('/api/case_tmpl/delete', { id: id })
      if (val.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success',
        })
        this.Usetemplate()
      }
    },
    //模板修改
    tmb_alter(row) {
      this.tmb_Index = 2
      this.addtmb = false
      this.tmb_id = row.id
      this.ruleForm.tmpl_name = row.tmpl_name
      this.ruleForm.tmpl_content = row.tmpl_content
    },
    //脱水量计算
    Target_dewatering() {
      this.create.target_warter =
        Number(this.create.befor_clean_weight) +
        Number(this.create.huixue_num) +
        Number(this.create.intake) -
        Number(this.create.dry_weight)
    },
    errorHandler() {},
    // 关于实际体重计算
    Throughweightafter() {
      this.create.befor_clean_weight =
        Number(this.create.befor_weight) - Number(this.create.add_weight)

      // 脱水量计算
      this.create.target_warter =
        Number(this.create.befor_clean_weight) +
        Number(this.create.huixue_num) +
        Number(this.create.intake) -
        Number(this.create.dry_weight)

      this.weight(this.create.befor_clean_weight)
    },
    //关于附加体重的计算
    Theadditionalweight() {
      this.create.befor_clean_weight =
        Number(this.create.befor_weight) - Number(this.create.add_weight)
    },
    // 床位号选择弹窗
    bed() {
      this.bedval = true
    },
    // 穿刺信息选择弹窗
    desc() {
      this.puncture = true
    },
    // 分组选择
    async receptions(index, id) {
      let data = {
        bed_area_id: id,
        current: 1,
        size: 999,
        hospital_area_id: this.sid,
        bed_id: this.create.bed_id,
      }
      console.log(data)
      const res = await service.post('/api/bed/list', data)
      if (res.code === 0) {
        this.bed_no = res.data.records
      }
      this.receptionIndex = index
    },
    // 床位号选择
    bidno(index, row) {
      this.preconval = row
      this.bidIndex = index
    },
    // 穿刺弹窗确认
    Confirmpuncture() {
      if (this.radiopiercing === '1') {
        this.create.chuanci_desc =
          'A:' +
          this.Amethodpuncture +
          this.Apiercingtype +
          this.AThepiercing +
          this.Adistance +
          ',' +
          'V:' +
          this.Vmethodpuncture +
          this.Vpiercingtype +
          this.VThepie +
          this.Vdistance +
          ',' +
          'AV:' +
          this.AVdistance
      } else {
        this.create.chuanci_desc = this.AVpuncture
      }
      this.puncture = false
    },
  },
}
</script>

<style scoped lang="scss">
#printArea {
  position: absolute;
  width: 100%;
  z-index: -1;
  .printAreaBox {
    .name {
      text-align: center;
      font-size: 18px;
      color: #000000;
      border-bottom: 2px solid #000000;
      line-height: 40px;
    }
    .time {
      font-size: 12px;
      color: #000;
      margin-top: 10px;
      text-align: right;
    }
    .print-item {
      padding-left: 10px;
      font-size: 12px;
      color: #000;
      margin-top: 10px;
      .pl {
        margin-right: 14px;
      }
    }
  }
}
.steps-item {
  border-bottom: 2px solid #999999;
  height: 40px;
  position: relative;
  width: 11.11%;
  &.active {
    border-color: #3166ae;
    .steps-item-node {
      background-color: #3166ae;
    }
  }
  .steps-item-node {
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #999999;
    &.right {
      background-color: #999999;
      left: initial;
      right: 0;
      &.active {
        background-color: #3166ae;
      }
    }
  }
}
.hande {
  .el-select {
    width: 124px;
  }
  .el-input {
    width: 144px;
  }
  .el-form-item {
    margin-right: 20px;
  }
  &:deep(.el-form-item__content .el-date-editor.el-input) {
    width: 160px;
  }
}
.hande {
  background: #ffffff;
  border-radius: 4px;
  opacity: 1;
  padding: 8px;
  padding-bottom: 0;
  .el-form {
    margin-top: 0;
  }
}
.Qiando {
  width: 80px;
  height: 32px;
  background: #3166ae;
  opacity: 1;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}
.Qiandon {
  width: 80px;
  height: 32px;
  background: #fff;
  opacity: 1;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #3166ae;
  text-align: center;
  line-height: 32px;
  border: 1px solid rgba(49, 102, 174, 1);
  cursor: pointer;
}
.rights {
  display: flex;
  justify-content: space-around;
  width: 104px;
  height: 32px;
  background: #3166ae;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  padding-top: 6px;
  cursor: pointer;
}
.icons {
  width: 19px;
  height: 19px;
  background: #ffffff;
  opacity: 1;
  color: rgba(49, 102, 174, 1);
  border-radius: 15px;
  text-align: center;
  padding-top: 1px;
  font-size: 12px;
}
.tobys {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.el-input__icon {
  cursor: pointer;
}
.bodycliss {
  display: flex;
  margin-top: 8px;
  /* margin-bottom: 70px; */
  height: 100%;
  overflow-y: auto;
}
.lifebody {
  width: 216px;
  /* height: 812px; */
  background: #ffffff;
  // border-radius: 4px;
  opacity: 1;
  padding: 6px;
  overflow-y: scroll;
  border-right: 1px solid #dcdfe6;
}
.lifebody::-webkit-scrollbar {
  display: none;
}
.zy-bord {
  width: calc(100% - 4px);
  margin-left: 2px;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px;
  cursor: pointer;
  &.active {
    background: rgba(49, 102, 174, 1);
    color: #ffffff;
  }
}
.Jiezhen {
  width: 52px;
  height: 24px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #f4a939;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #f4a939;
  text-align: center;
  line-height: 24px;
}
.txcis {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  width: 80px;
}
.txciss {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  width: 80px;
}
.pcls {
  width: 20px;
  height: 20px;
  background-color: RGBA(234, 240, 247, 1);
  color: rgba(49, 102, 174, 1);
  border-radius: 20px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
}
.QXbtn {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #3166ae;
  cursor: pointer;
}
.QEbtn {
  width: 80px;
  height: 32px;
  background: #3166ae;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: none;
  color: #fff;
  cursor: pointer;
}
.BCboder {
  width: 70px;
  height: 24px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  margin-top: 4px;
}
.starclis {
  width: 100px;
  height: 40px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  margin-left: 50px;
}
.a7 {
  color: red;
  font-size: 9px;
  margin-right: 2px;
}
.inputel {
  width: 222px;
}
.righbody {
  width: calc(100% - 224px);
  margin-left: 8px;
  background: #ffffff;
  border-radius: 4px;
  opacity: 1;
  padding: 0 8px 8px 8px;
  overflow-y: scroll;
}
.righbody::-webkit-scrollbar {
  display: none;
}
.textcliss {
  margin: 3px 10px;
  color: #444444;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
}
.butnli {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #3166ae;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #3166ae;
  text-align: center;
  line-height: 32px;
  margin-right: 10px;
  cursor: pointer;
  overflow: hidden;
}
.butnlis {
  width: 80px;
  height: 32px;
  background: rgba(49, 102, 174, 1);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #3166ae;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 32px;
  margin-right: 10px;
  cursor: pointer;
  overflow: hidden;
}
.jniop {
  width: 6px;
  height: 18px;
  background: #3166ae;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  margin-top: 4px;
  margin-right: 10px;
}
.juliss {
  width: 97%;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #333333;
}
.bodytxet {
  text-align: right;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
.circie {
  color: #3166ae;
  font-size: 18px;
  margin-top: 2px;
  cursor: pointer;
  margin-left: 11px;
}
.inputlis {
  width: 100px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  border-right: none;
  outline: none;
  padding-left: 10px;
  color: #666;
  font-size: 14px;
}
.KGclis {
  width: 32px;
  height: 32px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  position: relative;
  right: 10px;
}
.KGcliss {
  width: 32px;
  height: 32px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.BFbi {
  width: 90px;
  height: 32px;
  position: relative;
  right: 10px;
  border: 1px solid #ccc;
  border-right: none;
  outline: none;
  border-left: none;
  padding-left: 10px;
  color: #666;
  font-size: 14px;
}
.BFbiclis {
  width: 32px;
  height: 32px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-left: none;
  position: relative;
  right: 15px;
}
.divclis {
  width: 200px;
  height: 36px;
  background: #3166ae;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  color: #fff;
  text-align: center;
  line-height: 36px;
  margin-top: 10px;
  margin-left: 30px;
  cursor: pointer;
}

.pclss {
  width: 18px;
  height: 18px;
  background: rgba(255, 62, 62, 0.1);
  opacity: 1;
  color: #ff3e3e;
  border-radius: 10px;
  text-align: center;
  padding-top: 1px;
  cursor: pointer;
}
.pclsxs {
  width: 18px;
  height: 18px;
  opacity: 1;
  border-radius: 10px;
  text-align: center;
  padding-top: 1px;
  cursor: pointer;
  background: #ff3e3e;
  color: #fff;
}
.Steps {
  height: 64px;
  background: #ffffff;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 8px;
  padding: 8px 10px;
  &:deep(.el-steps) {
    .el-step__title {
      font-size: 14px;
      line-height: 32px;
    }
  }
}
.fiall {
  display: flex;
  position: fixed;
  bottom: 1%;
  right: 2%;
}
.warings {
  width: 80px;
  height: 80px;
  background: #3166ae;
  color: #fff;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  margin: 0 20px;
  cursor: pointer;
}
.waringss {
  width: 80px;
  height: 80px;
  background: rgba(49, 102, 174, 0.1);
  color: #3166ae;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  opacity: 1;
  margin: 0 20px;
  cursor: pointer;
}
.waringstext {
  width: 48px;
  height: 40px;
  margin: 18px auto;
}
.Close {
  width: 18px;
  height: 18px;
  background: rgba(255, 62, 62, 0.1);
  opacity: 1;
  color: #ff3e3e;
  border-radius: 50%;
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
  margin-top: 5px;
}
.clostext {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-top: 5px;
}
.closhand {
  width: 100%;
  display: flex;
  margin: 10px 0;
  height: 100px;
  flex-wrap: wrap;
}
.tmb_hande {
  height: 32px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  color: #fff;
  margin: 15px 0;
}
.el-iconright {
  width: 18px;
  height: 18px;
  background: #ffffff;
  opacity: 1;
  border-radius: 50%;
  color: #3166ae;
  margin-right: 10px;
  padding-top: 2px;
}
.tmb_tab {
  display: flex;
  justify-content: space-around;
}
.tmb_btn {
  width: 21px;
  height: 21px;
  background: rgba(104, 71, 236, 0.3);
  opacity: 1;
  font-size: 14px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #6847ec;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.tmb_btn:hover {
  background: #6847ec;
  color: #fff;
}
.tmb_btndele {
  width: 21px;
  height: 21px;
  background: rgba(255, 62, 62, 0.1);
  opacity: 1;
  font-size: 14px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #ff3e3e;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.tmb_btndele:hover {
  background: #ff3e3e;
  color: #fff;
}
.tmb_btnalter {
  width: 21px;
  height: 21px;
  background: rgba(49, 102, 174, 0.3);
  opacity: 1;
  font-size: 14px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #3166ae;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.tmb_btnalter:hover {
  background: #6847ec;
  color: #fff;
}
.tmb_body {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  height: 30px;
  line-height: 30px;
  margin: 20px 0;
}

.deleiDialysis:hover {
  color: red;
}
.deleiDialysis {
  margin: 10px 0;
  cursor: pointer;
}
.clinicalreception {
  width: 52px;
  height: 24px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #f4a939;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #f4a939;
  line-height: 24px;
  margin-top: 2px;
}
.bottmcliss {
  height: 160px;
}
.hanbuts {
  display: flex;
  justify-content: flex-start;
}
.headfixed {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
  width: 100%;
  margin-bottom: 8px;
  // padding-bottom: 10px;
  padding-top: 10px;
  background-color: #fff;
}
.Dialysis {
  display: flex;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.12);
  flex-wrap: wrap;
  align-content: flex-start;
  margin-bottom: 25px;
}
.Thepiercing {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin: 10px 0;
}
.bidcliss {
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0 10px;
  color: #6847ec;
  border: 1px solid #6847ec;
  cursor: pointer;
}
.bidclis {
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid #e5e6eb;
  cursor: pointer;
  border-left: 0;
}
.recepbody {
  width: 95%;
  margin: 0 auto;
  background-color: rgba(49, 102, 174, 0.1);
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  .body_bid {
    width: 110px;
    height: 64px;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    padding: 5px 0;
    margin: 5px;
    text-align: center;
  }
  .body_bids {
    width: 110px;
    height: 64px;
    border-radius: 4px;
    color: #fff;
    background-color: #0057bb;
    cursor: pointer;
    padding: 5px 0;
    margin: 5px;
    text-align: center;
  }
  .display_flex {
    display: flex;
    justify-content: space-between;
  }
}
</style>

<style scoped>
.demo-ruleForm >>> .el-form-item__label {
  width: 120px !important;
  text-align: right;
}

.demo-ruleForm >>> .el-form-item__content {
  width: 251px !important;
  line-height: 29px !important;
}
.demo-ruleForm >>> .el-textarea__inner {
  /* width: 835px !important; */
}
.el-input-group__append .el-select >>> .el-input__inner {
  border: none !important;
}
.el-input-group__append .el-select >>> .el-input {
  width: 100px;
  padding-left: 10px;
}
.demo-form >>> .el-form-item__label {
  width: 120px !important;
  text-align: right;
}
.demo-form >>> .el-select {
  width: 120px;
}
.demo-form {
  margin-left: 20px;
}
</style>
<style media="print" scoped>
@page {
  size: auto;
  margin: 3mm;
}

/* html {
  background-color: #ffffff;
  height: auto;
  margin: 0px;
} */
</style>
