<template>
  <div class="adviceBox">
    <longIerm
      :patientData="patientData"
      :searchListData="searchListData"
      ref="longIermRef"
      @addCallback="addTemporary"
    ></longIerm>
    <temporary
      :patientData="patientData"
      :searchListData="searchListData"
      @stepsUpdate="stepsUpdate"
      ref="temporaryRef"
    ></temporary>
  </div>
</template>

<script>
import { ref, nextTick, reactive, toRefs, onMounted, watch } from 'vue'
import service from '@/utils/request'
import longIerm from './longIerm.vue'
import temporary from './temporary.vue'
import Rule from '@/utils/rule'
import { ElMessage } from 'element-plus'
export default {
  name: 'patentCase',
  components: {
    longIerm,
    temporary,
  },
  props: ['patientData', 'searchListData'],
  emits: ['stepsUpdate'],
  setup(props, ctx) {
    const state = reactive({
      ruleForm: {},
      userList: [],
      visible: false,
      saveLoading: false,
      ruleFormRef: null,
      Rule: Rule,
      isRecord: false,
    })
    const temporaryRef = ref(null)
    const longIermRef = ref(null)
    const addTemporary = () => {
      nextTick(() => {
        temporaryRef.value.getDataList()
      })
    }
    const getDataList = () => {
      nextTick(() => {
        temporaryRef.value.getDataList()
        longIermRef.value.getDataList()
      })
    }
    watch(
      () => props.patientData.patient_id,
      (val) => {
        if (val) {
          state.ruleForm.record_id = props.patientData.record_id
          state.ruleForm.patient_id = props.patientData.patient_id
        } else {
          state.ruleForm.record_id = 0
          state.ruleForm.patient_id = 0
        }
        getOnList()
      }
    )
    onMounted(() => {
      if (props.patientData.patient_id) {
        state.ruleForm.record_id = props.patientData.record_id
        state.ruleForm.patient_id = props.patientData.patient_id
      } else {
        state.ruleForm.record_id = 0
        state.ruleForm.patient_id = 0
      }
      getUserList()
      getOnList()
    })
    const stepsUpdate = () => {
      ctx.emit('stepsUpdate')
    }
    const getOnList = async () => {
      if (!state.ruleForm.record_id) return
      let res = await service.post('/api/dialysis_record/is_on', {
        record_id: state.ruleForm.record_id,
      })
      if (res.code === 0) {
        state.isRecord = res.data.is_on
      }
    }
    const getUserList = async () => {
      let res = await service.post('/api/user/list')
      if (res.code === 0) {
        state.userList = res.data.records
      }
    }
    const saveLongItem = () => {
      state.ruleFormRef.validate(async (valid) => {
        if (valid) {
          let url = ''
          if (state.isRecord) {
            url = '/api/dialysis_record/off'
          } else {
            url = '/api/dialysis_record/on'
          }
          let res = await service.post(url, state.ruleForm)
          if (res.code === 0) {
            ElMessage.success(res.msg)
            state.visible = false
            ctx.emit('stepsUpdate')
            getOnList()
          }
        }
      })
    }

    return {
      ...toRefs(state),
      addTemporary,
      temporaryRef,
      longIermRef,
      getDataList,
      saveLongItem,
      stepsUpdate,
    }
  },
}
</script>

<style scoped lang="scss">
.computer {
  position: fixed;
  bottom: 5%;
  right: 5%;
  cursor: pointer;
  z-index: 10;
  img {
    width: 64px;
    height: 64px;
  }
}
</style>
