<template>
  <div>
    <!-- 临时医嘱 -->
    <div class="hande">
      <!-- <div class="upButtom">
        <el-button class="color-main" type="primary" @click="addTesting">
          <i class="fa fa-plus-circle"></i>新增
        </el-button>
      </div> -->

      <!-- 表格部分 -->
      <div style="margin-top: 20px">
        <el-table
          :data="tableData"
          size="small"
          stripe
          height="350"
          v-loading="loading"
        >
          <el-table-column prop="shousuoya" width="120" label="收缩压(mmHg)" />
          <el-table-column prop="dongmaiya" width="120" label="动脉压(mmHg)" />
          <el-table-column prop="kuamoya" width="120" label="跨膜压(mmHg)" />
          <el-table-column prop="shuzhangya" width="120" label="舒张压(mmHg)" />
          <el-table-column prop="jingmaiya" width="120" label="静脉压(mmHg)" />
          <el-table-column prop="xinlv" width="120" label="脉搏/心率(次/min)" />
          <el-table-column prop="tiwen" width="80" label="体温(°C)" />
          <el-table-column prop="record_time" width="160" label="记录时间" />
          <el-table-column prop="diandao" width="110" label="呼吸(次/min)" />
          <el-table-column
            prop="dxueliuliang"
            width="120"
            label="血流量(ml/mi)"
          />
          <el-table-column prop="chaolvlv" width="120" label="超滤率(ml/h)" />
          <el-table-column prop="chaolvliang" width="120" label="超滤量(ml)" />
          <!-- <el-table-column
            prop="chanci"
            width="160"
            label="穿刺(置管)渗血、血肿"
          /> -->
          <el-table-column prop="jinyeliang" width="120" label="进液量(ml)" />
          <el-table-column prop="zhihuanye" width="120" label="置换液(L)" />
          <el-table-column prop="nanongdu" width="120" label="钠浓度(mol/L)" />
          <el-table-column prop="jiwen" width="80" label="机温(℃)" />
          <el-table-column
            prop="zhengzhuang"
            width="140"
            show-overflow-tooltip
            label="症状"
          />
          <el-table-column
            prop="chuli"
            width="140"
            show-overflow-tooltip
            label="处理"
          />
          <el-table-column prop="nurse.name" label="护士签名" />
        </el-table>
      </div>
      <!--分页 -->
      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <Add
      :patientData="patientData"
      ref="addRef"
      @refreshDataList="refreshData"
    ></Add>
  </div>
</template>

<script>
import service from '@/utils/request'
import { reactive, toRefs, watch, onMounted, nextTick } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import Add from './add.vue'
export default {
  components: {
    Add,
  },
  props: {
    patientData: {
      type: Object,
      required: true,
    },
  },
  emits: ['stepsUpdate'],
  setup(props, ctx) {
    const state = reactive({
      formData: {},
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      addRef: null,
      loading: true,
      searchData: {},
      dataListLoading: false,
      tableData: [],
    })

    watch(
      () => props.patientData.record_id,
      (val) => {
        if (val) {
          state.searchData = {
            patient_id: props.patientData.patient_id,
            record_id: props.patientData.record_id,
          }
        } else {
          state.searchData = {
            patient_id: 0,
            record_id: 0,
          }
        }
        getDataList()
      }
    )

    onMounted(() => {
      initData()
    })
    const initData = () => {
      if (props.patientData.patient_id) {
        state.searchData = {
          patient_id: props.patientData.patient_id,
          record_id: props.patientData.record_id,
        }
      } else {
        state.searchData = {
          patient_id: 0,
          record_id: 0,
        }
      }
      getDataList()
    }

    const refreshData = () => {
      getDataList()
      ctx.emit('stepsUpdate')
    }
    const getDataList = async () => {
      state.loading = true
      state.searchData.current = state.pageData.currentPage
      state.searchData.size = state.pageData.pageSize
      state.dataListLoading = true
      let res = await service.post('/api/estimate/monitor', state.searchData)
      state.dataListLoading = false
      if (res.code === 0) {
        state.tableData = res.data.records
        state.pageData.totalSum = res.data.total
        state.loading = false
      }
    }
    const handleSizeChange = (val) => {
      state.pageData.pageSize = val
      getDataList()
    }

    const handleCurrentChange = (val) => {
      state.pageData.currentPage = val
      getDataList()
    }

    // 新增弹窗确认
    const addTesting = () => {
      nextTick(() => {
        state.addRef.initData(state.searchData)
      })
    }
    // 删除记录
    const delParient = (item) => {
      ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post('api/estimate/deleteMonitor', {
            id: item.id,
            patient_id: state.searchData.patient_id,
            record_id: state.searchData.record_id,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
          }
        })
        .catch(() => {})
    }
    // 修改
    const modParient = (item) => {
      nextTick(() => {
        state.addRef.initData(state.searchData, item)
      })
    }
    return {
      ...toRefs(state),
      handleSizeChange,
      handleCurrentChange,
      addTesting,
      delParient,
      modParient,
      getDataList,
      refreshData,
    }
  },
}
</script>

<style scoped lang="scss">
.upButtom {
  text-align: right;
}
.hande {
  padding: 10px;
  height: 557px;
  border-radius: 8px;
  opacity: 1;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(49, 102, 174, 1),
      rgba(49, 102, 174, 0.3),
      rgba(49, 102, 174, 0),
      rgba(49, 102, 174, 0)
    )
    1 1;
}
.Dsefault {
  display: flex;
  justify-content: space-around;
}
.Colum {
  width: 21px;
  height: 21px;
  background: #f4a939;
  opacity: 1;
  color: #fff;
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  line-height: 21px;
}
.Dsefault span:hover {
  background: #ff3e3e !important;
  color: #fff !important;
}
</style>
