<template>
  <div class="addTesting">
    <BaseDialog
      :title="!ruleForm.id ? '新增监测记录' : '修改监测记录'"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="1080px"
    >
      <div class="cont-main">
        <el-scrollbar>
          <!-- 输入框部分 -->
          <el-form
            :model="ruleForm"
            :inline="true"
            label-width="120px"
            label-position="right"
            class="demo-ruleForm col-333"
          >
            <el-form-item label="静脉压：">
              <el-input v-model="ruleForm.jingmaiya" placeholder="请输入">
                <template #append>mmHg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="动脉压：">
              <el-input v-model="ruleForm.dongmaiya" placeholder="请输入">
                <template #append>mmHg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="跨膜压：">
              <el-input v-model="ruleForm.kuamoya" placeholder="请输入">
                <template #append>mmHg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="舒张压：">
              <el-input v-model="ruleForm.shuzhangya" placeholder="请输入">
                <template #append>mmHg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="收压缩：">
              <el-input v-model="ruleForm.shousuoya" placeholder="请输入">
                <template #append>mmHg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="脉搏/心率：">
              <el-input v-model="ruleForm.xinlv" placeholder="请输入">
              </el-input>
            </el-form-item>
            <el-form-item label="体温：">
              <el-input v-model="ruleForm.tiwen" placeholder="请输入">
                <template #append>℃</template>
              </el-input>
            </el-form-item>
            <el-form-item label="呼吸：">
              <el-input v-model="ruleForm.diandao" placeholder="请输入">
              </el-input>
            </el-form-item>
            <el-form-item label="血流量：">
              <el-input v-model="ruleForm.dxueliuliang" placeholder="请输入">
                <template #append>ml/min</template>
              </el-input>
            </el-form-item>
            <el-form-item label="超滤率：">
              <el-input v-model="ruleForm.chaolvlv" placeholder="请输入">
                <template #append>ml/h</template>
              </el-input>
            </el-form-item>
            <el-form-item label="超滤量：">
              <el-input v-model="ruleForm.chaolvliang" placeholder="请输入">
                <template #append>ml</template>
              </el-input>
            </el-form-item>
            <el-form-item label="穿刺(置管)渗血">
              <el-input v-model="ruleForm.chanci" placeholder="请输入">
              </el-input>
            </el-form-item>
            <el-form-item label="进液量 ：">
              <el-input v-model="ruleForm.jinyeliang" placeholder="请输入">
                <template #append>ml</template>
              </el-input>
            </el-form-item>
            <el-form-item label="置换液：">
              <el-input v-model="ruleForm.zhihuanye" placeholder="请输入">
                <template #append>L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="钠浓度：">
              <el-input v-model="ruleForm.nanongdu" placeholder="请输入">
                <template #append>mol/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="机温：">
              <el-input v-model="ruleForm.jiwen" placeholder="请输入">
                <template #append>(℃)</template>
              </el-input>
            </el-form-item>
            <el-form-item class="addr but" label="症状：">
              <el-input
                v-model="ruleForm.zhengzhuang"
                type="textarea"
                :rows="2"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <div class="templateBut">
              <i @click="symptomBut(1)" class="fa fa-plus-circle"></i>
            </div>
            <el-form-item class="addr but" label="处理：">
              <el-input
                v-model="ruleForm.chuli"
                type="textarea"
                :rows="2"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <div class="templateBut">
              <i @click="symptomBut(2)" class="fa fa-plus-circle"></i>
            </div>
            <el-form-item label="记录时间：">
              <el-date-picker
                v-model="ruleForm.record_time"
                type="datetime"
                value-format="YYYY-MM-DD HH:mm:ss"
                placeholder="请选择"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="护士签名：">
              <el-select  v-model="ruleForm.nurse_id" placeholder="请选择">
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-scrollbar>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="estimateSave"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
    <BaseDialog
      :title="symptomData.type === 1 ? '新增症状' : '新增处理'"
      :isshow="isSymptom"
      @handleShow="
        (val) => {
          isSymptom = val
        }
      "
      width="600px"
    >
      <div class="symptomBox">
        <el-checkbox-group v-model="symptomData.checkList">
          <el-checkbox
            v-for="item in symptom_list"
            :key="item.id"
            :label="item.name"
          ></el-checkbox>
        </el-checkbox-group>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="isSymptom = false"
          >
            取消
          </el-button>
          <el-button
            @click="symptomSave"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import service from '@/utils/request'
import { ElMessage } from 'element-plus'
import { ruleTimeYear } from '@/utils/tool'
import BaseDialog from '@/components/Dialog/index.vue'
export default {
  components: {
    BaseDialog,
  },
  emits: ['refreshDataList'],
  setup(props, ctx) {
    const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
    const state = reactive({
      ruleForm: {},
      visible: false,
      saveLoad: false,
      symptom_list: [],
      userList: [],
      isSymptom: false,
      symptomData: {
        type: 1,
        checkList: [],
      },
      monitorDefault: '',
    })
    onMounted(() => {
      getUserList()
    })
    const getMonitorDefault = async (row) => {
      let res = await service.post('/api/estimate/get_monitor_default', {
        record_id: row.record_id,
      })
      if (res.code === 0) {
        state.monitorDefault = res.data.dxueliuliang
      }
    }
    const initData = async (row, item) => {
      state.visible = true
      await getMonitorDefault(row)
      formInit(row)
      if (item.id) {
        state.ruleForm = item
      }
    }
    const formInit = (row) => {
      state.ruleForm = {
        patient_id: row.patient_id,
        record_id: row.record_id,
        diandao: '14.7',
        nanongdu: '140',
        jiwen: '37',
        dxueliuliang: state.monitorDefault,
        record_time: ruleTimeYear(new Date()),
        nurse_id: userInfo.uid,
      }
    }
    const symptomBut = async (type) => {
      state.isSymptom = true
      state.symptomData.type = type
      state.symptomData.checkList = []
      let res = ''
      if (type === 1) {
        res = await service.post('/api/estimate/symptomList')
      } else {
        res = await service.post('/api/estimate/del')
      }
      if (res.code === 0) {
        state.symptom_list = res.data
      }
    }
    const symptomSave = () => {
      if (state.symptomData.type === 1) {
        state.ruleForm.zhengzhuang = state.symptomData.checkList.join(',')
      } else {
        state.ruleForm.chuli = state.symptomData.checkList.join(',')
      }
      state.isSymptom = false
    }
    const getUserList = async () => {
      let res = await service.post('/api/estimate/nurse')
      if (res.code === 0) {
        state.userList = res.data
      }
    }
    const estimateSave = async () => {
      state.saveLoad = true
      let url = ''
      if (state.ruleForm.id) {
        url = 'api/estimate/monitorEdit'
      } else {
        url = '/api/estimate/monitorAdd'
      }
      let res = await service.post(url, state.ruleForm)
      state.saveLoad = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        state.visible = false
        ctx.emit('refreshDataList')
      }
    }
    return {
      ...toRefs(state),
      estimateSave,
      initData,
      symptomBut,
      symptomSave,
    }
  },
}
</script>

<style scoped lang="scss">
.cont-main {
  flex: 1;
  // max-height: 600px;
  // overflow-y: auto;
  // overflow-x: hidden;
  margin-top: 16px;
  .el-form {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;
  }
  .el-form-item {
    width: 33%;
    margin-right: 0.5%;
    &.addr {
      width: 100%;
      margin-right: 0;
      &.but {
        width: calc(100% - 62px);
      }
    }
  }
  .templateBut {
    width: 40px;
    margin-left: 12px;
    display: inline-block;
    position: relative;
    top: -4px;
    .fa {
      color: #3166ae;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .el-form-item:nth-child(3n + 3) {
    margin-right: 0;
  }
}
.symptomBox {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 10px;
  &:deep(.el-checkbox) {
    width: 100%;
    white-space: pre-wrap;
  }
}
</style>
